import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import ShareButtons from "../components/share-buttons"
import Breadcrumb from '../components/breadcrumb'
import { BlogTemplateHeader } from "../components/Blog-Page/blog-template-header"
import ShareButtons from "../components/share-buttons"

class BlogPageTemplate extends React.Component {
    render() {
        const blogpage = this.props.data.contentfulBlog
        const image = getImage(blogpage.image)
        const url = "https://kaylarconstruction.com/" + blogpage.slug
        return (
            <Layout>
                <Seo
                    keywords={[blogpage.category]}
                    title={blogpage.title}
                    description={blogpage.description}
                    location={blogpage.slug}
                    blogpage={blogpage}
                    isBlogPost={true}
                    type="BlogPosting"
                />
                <BlogTemplateHeader Category={blogpage.category} />
                <div>
                    <div className="bg-white border border-gray-200 shadow-lg">
                        <nav className="flex container mx-auto px-4 pt-1 pb-3" aria-label="Breadcrumb">
                            <Breadcrumb links={[
                                { to: '/', label: 'Home' },
                                { to: '/blog', label: 'Blog' },
                                { to: blogpage.slug, label: blogpage.title },
                            ]} />
                        </nav>
                    </div>
                    <div className="container mx-auto px-5 lg:px-10 pt-10 lg:pt-16 pb-4 lg:pb-10">
                        <div className="max-w-4xl xl:mx-auto 2xl:ml-blogHeading pb-4">
                            <p className="text-gray-500 text-sm lg:text-lg uppercase">{blogpage.category}</p>
                            <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl">{blogpage.title}</h1>
                            <p className="font-light pb-1">By {blogpage.author} - {blogpage.date}</p>
                        </div>
                        <div className="flex justify-center">
                            <GatsbyImage className="w-full xl:w-9/12 2xl:w-blog shadow-lg rounded-lg z-0" src="" image={image} title={blogpage.title} alt={blogpage.title} />
                        </div>
                        <div className="pt-5 mx-auto bg-white mt-4 px-5 rounded-lg shadow-lg">
                            <div className="flex mx-auto">
                                <span className="py-2 px-2 text-sm ">Share This Article:</span>
                                <div className="pt-2">
                                    <ShareButtons url={url} description={blogpage.description} title={blogpage.title} />
                                </div>
                            </div>
                        </div>
                        <div className="pt-5 mx-auto" dangerouslySetInnerHTML={{
                            __html: this.props.data.contentfulBlog.body.childMarkdownRemark.html,
                        }}>
                        </div>

                        <div className="text-center pt-5 pb-10 mx-auto justify-center md:flex md:inline space-x-3 md:gap-x-5">
                            <Link to="/contact" title="Contact Kaylar Construction" alt="Contact Kaylar Construction">
                                <button className="relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-300 ease-in-out text-sm border-lightblue bg-lightblue text-white hover:bg-darkblue hover:text-white">
                                    <span>Contact Us</span>
                                </button>
                            </Link>
                            <Link to="/" title="Visit Kaylar Construction Home Page" alt="Visit Kaylar Construction Home Page">
                                <button className="relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-300 ease-in-out text-sm border-lightblue bg-lightblue text-white hover:bg-darkblue hover:text-white">
                                    <span>Our Website</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default BlogPageTemplate
export const pageQuery = graphql`
query ContentfulBlogBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulBlog( slug: {eq: $slug }) {
        title
        category
        slug
        author
        body {
            childMarkdownRemark {
                html
            }
         }
        description
        date(formatString: "MMMM Do, YYYY")
        image {
             gatsbyImageData (
                width: 600
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
                quality: 100
                )
                title
                description
        }
    }
}
`